import React from "react";
import * as styles from "./productsTable.module.scss";
import { getPrices, getToday } from "../../utils/utils";

const today = getToday();

const ProductsTable = ({ productList, categoryId, name }) => {
  const tableData = getPrices(productList, categoryId);
  return (
    <div className={styles.priceTableOutsideContainer}>
      <div className={styles.wContainer}>
        <h2 id="preise" className={styles.heading}>
          {`Tagesaktueller ${name} vom ${today}`}
        </h2>
        <center className={styles.subheading}>
          *Indikative Preise ab 500 kg basierend auf Preisen in Deutschland für
          Teilladungen, angeliefert.
        </center>
        <div className={styles.productsTable}>
          {tableData.map(item => (
            <div className={styles.productRow}>
              <div className={styles.nameColumn}>
                <span className={styles.productName} itemProp="name">
                  {item.name}
                </span>
              </div>
              {item.price.map((element, index) => {
                if (index < 2) {
                  return null;
                }
                return (
                  <div className={styles.priceColumn}>
                    <span itemprop="price" className={styles.priceValue}>
                      € {Number(element.price).toFixed(2)}
                      <span className={styles.priceLegend}> pro kg*</span>
                    </span>
                    <span itemprop="priceCurrency" content="EUR"></span>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.priceTableBackgroundContainer}>
        <div className={styles.darkBackgroundDiagonal}></div>
        <div className={styles.darkBackground}></div>
      </div>
    </div>
  );
};

export default ProductsTable;
