import React from "react";
import * as styles from "./priceComparisonBanner.module.scss";

import PCBannerOrangeMobile from "../../images/PCBanner-OrangeMobile.png";
import PCBannerBlueMobile from "../../images/PCBanner-BlueMobile.png";
import { StaticImage } from "gatsby-plugin-image";

const PriceComparisonBanner = props => (
  <div className={styles.priceComparisonBanner}>
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h3>Industriepreis-Entwicklungen</h3>
        <p>
          Monatlich neutralen Überblick über die Entwicklungen der Altmetalle
          und Börse erhalten.
        </p>
        <a href="https://www.schrott24.de/faq/preisalarm/">Jetzt abonnieren</a>
      </div>
      <StaticImage
        className={styles.image}
        src={"../../images/PCBanner-BlueMobile.png"}
        alt="PCBannerBlueMobile"
      />
    </div>
    <div className={styles.topLayer}></div>
  </div>
);

export default PriceComparisonBanner;
